<template>
  <div class="service detail wrap">
    <div class="text-bg im">
      <div class="text-wrap">
        <p>
          {{ $t('solution.detail.iam.lineText.01') }}
        </p>
        <p>
          {{ $t('solution.detail.iam.lineText.02') }}
        </p>
        <p>
          {{ $t('solution.detail.iam.lineText.03') }}
        </p>
      </div>
    </div>
    <HalfPageLayout class="im title">
      <template #half-left>
        <div class="img im"></div>
      </template>
      <template #half-right>
        <div class="inner-contents">
          <div class="contents-title">
            <h3 style="line-height: 1.1">
              {{ $t('solution.title.iam') }}
            </h3>
            <h6>Identity and Access Management</h6>
            <h4>
              {{ $t('solution.detail.iam.subTitle') }}
            </h4>
          </div>
          <h5>
            {{ $t('solution.detail.iam.desc') }}
          </h5>
        </div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="im summary">
      <template #half-left>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('solution.detail.iam.firstPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:user-circle-plus-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('solution.detail.iam.firstPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('solution.detail.iam.firstPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1000">
              <Icon icon="ph:user-circle-minus-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('solution.detail.iam.firstPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('solution.detail.iam.firstPage.contents.02.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1500">
              <Icon icon="ph:folder-simple-user-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('solution.detail.iam.firstPage.contents.03.title') }}
                </dt>
                <dd>
                  {{ $t('solution.detail.iam.firstPage.contents.03.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
      <template #half-right>
        <div class="img visual-01"></div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="im summary">
      <template #half-left>
        <div class="img visual-02"></div>
      </template>
      <template #half-right>
        <div class="animation-bg">
          <div class="gear">
            <SvgGear />
          </div>
          <div class="gear">
            <SvgGear />
          </div>
        </div>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('solution.detail.iam.secondPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:user-circle-plus-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('solution.detail.iam.secondPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('solution.detail.iam.secondPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1000">
              <Icon icon="ph:user-circle-minus-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('solution.detail.iam.secondPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('solution.detail.iam.secondPage.contents.02.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="1500">
              <Icon icon="ph:folder-simple-user-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('solution.detail.iam.secondPage.contents.03.title') }}
                </dt>
                <dd>
                  {{ $t('solution.detail.iam.secondPage.contents.03.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
    </HalfPageLayout>
    <section class="section im benefit">
      <h2 class="hl section-title">
        {{ $t('solution.detail.iam.benefit.title') }}
      </h2>
      <div class="section-contents">
        <div class="inner-contents">
          <div class="contents-list">
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.01.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.01.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.02.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.02.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.03.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.03.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.04.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.04.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.05.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.05.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.06.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.06.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.07.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.07.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.08.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.08.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img im"></div>
              <dt>
                {{ $t('solution.detail.iam.benefit.contents.09.title') }}
              </dt>
              <dd>
                {{ $t('solution.detail.iam.benefit.contents.09.desc') }}
              </dd>
            </dl>
          </div>
        </div>
        <router-link to="/contact" class="button big line">
          {{ $t('service.detail.contact') }}
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import HalfPageLayout from '@/components/HalfPageLayout'
import { Icon } from '@iconify/vue'
import SvgGear from '@/components/SvgGear'

export default {
  name: 'SolutionDetailIm',
  components: { SvgGear, HalfPageLayout, Icon },
  data () {
    return {
      benefitIM: [
        { title: '내/외부 규제 준수', summary: '내외부 규제를 준수하며 계정의 생성, 변경, 삭제에 대한 감사 자료를 남기고 관리' },
        { title: '신속한 현황파악', summary: '사용자가 가지고 있는 모든 시스템 계정이 통합되어 계정을 조사하고 관리하는 시간 감소' },
        { title: '보안강화', summary: '권한 변동을 감지하여 권한 없는 사용자가 사내 주요 데이터에 접근하지 못하도록 관리' },
        { title: '고스트/휴면계정 통제', summary: '인사이동 / 퇴사한 직원의 계정 권한을 삭제하고 계정을 제거' },
        { title: '공용계정관리', summary: '계정의 공동 사용을 제한하고 관리' },
        { title: '통합권한관리', summary: '계정 생성과 동시에 적절한 권한을 부여하고 권한 조건에 부합되지 않는 계정 권한 박탈' },
        { title: '변동에 따른 권한 변경', summary: '인사이동에 따라 계정/권한을 회수하거나 삭제' },
        { title: '비용 절감', summary: '하나의 인증정보로 통합하여 헬프데스크 비용 절감' },
        { title: '생산성 향상', summary: '사용자 계정 관리 기능이 존재해 새로운 어플리케이션 개발 시 M/M 투입 불필요' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
