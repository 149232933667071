<template>
  <div class="service detail wrap">
    <div class="text-bg cloud">
      <div class="text-wrap">
        <p>
          {{ $t('service.detail.cloud.lineText.01') }}
        </p>
        <p>
          {{ $t('service.detail.cloud.lineText.02') }}
        </p>
        <p>
          {{ $t('service.detail.cloud.lineText.03') }}
        </p>
      </div>
    </div>
    <HalfPageLayout class="cloud title">
      <template #half-left>
        <div class="img cloud"></div>
      </template>
      <template #half-right>
        <div class="inner-contents">
          <div class="contents-title">
            <h3 style="line-height: 1.1">
              {{ $t('service.title.cloud') }}
            </h3>
            <h6>Cloud Service</h6>
            <h4>
              {{ $t('service.detail.cloud.subTitle') }}
            </h4>
          </div>
          <h5>
            {{ $t('service.detail.cloud.desc') }}
          </h5>
        </div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="cloud summary bg-light">
      <template #half-left>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('service.detail.cloud.firstPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:book-open-text-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.cloud.firstPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.cloud.firstPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
      <template #half-right>
        <div class="img visual-01"></div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="cloud summary bg-light">
      <template #half-left>
        <div class="img visual-02"></div>
      </template>
      <template #half-right>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('service.detail.cloud.secondPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:cloud-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.cloud.secondPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.cloud.secondPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
          </div>

        </div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="cloud summary bg-light">
      <template #half-left>
        <div class="inner-contents">
          <div class="section-title"  data-aos="fade" >
            <h3 class="hl">
              {{ $t('service.detail.cloud.thirdPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:shapes-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.cloud.thirdPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.cloud.thirdPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:lock-key-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.cloud.thirdPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.cloud.thirdPage.contents.02.desc') }}
                </dd>
              </dl>
            </div>
          </div>
          <router-link to="/contact" class="button big line" data-aos="fade" data-aos-delay="1000">
            {{ $t('service.detail.contact') }}
          </router-link>
        </div>
      </template>
      <template #half-right>
        <div class="img visual-03"></div>
      </template>
    </HalfPageLayout>

  </div>
</template>

<script>
import HalfPageLayout from '@/components/HalfPageLayout'
import { Icon } from '@iconify/vue'

export default {
  name: 'serviceDetailIm',
  components: { HalfPageLayout, Icon },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
