import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HeaderNav from '@/components/HeaderNav'
import FooterList from '@/components/FooterList'

import ContactView from '@/views/ContactView'
import TermsPage from '@/views/contact/TermsPage'

import SolutionView from '@/views/SolutionView'
import SolutionDetailSSO from '@/views/solution/SolutionDetailSSO'
import SolutionDetailIM from '@/views/solution/SolutionDetailIM'
import SolutionDetailOTP from '@/views/solution/SolutionDetailOTP'
import SolutionDetailDDH from '@/views/solution/SolutionDetailDDH'

import ServiceView from '@/views/ServiceView'
import ServiceDetailISIGN from '@/views/service/ServiceDetailISIGN'
import ServiceDetailSOF from '@/views/service/ServiceDetailSOF'
import ServiceDetailCL from '@/views/service/ServiceDetailCL'

const routes = [
  {
    path: '/',
    name: 'home',
    component:
    HomeView,
    HeaderNav,
    FooterList,
    ContactView,
    TermsPage,
    SolutionView,
    SolutionDetailIM,
    SolutionDetailSSO,
    SolutionDetailOTP,
    SolutionDetailDDH,

    ServiceView,
    ServiceDetailISIGN,
    ServiceDetailSOF,
    ServiceDetailCL
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/contact/terms',
    name: 'terms',
    component: TermsPage
  },
  {
    path: '/solution',
    name: 'solution',
    component: SolutionView
  },
  {
    path: '/solution/IM',
    name: 'solutionIM',
    component: SolutionDetailIM
  },
  {
    path: '/solution/SSO',
    name: 'solutionSSO',
    component: SolutionDetailSSO
  },
  {
    path: '/solution/OTP',
    name: 'solutionOTP',
    component: SolutionDetailOTP
  },
  {
    path: '/solution/DDH',
    name: 'solutionDDH',
    component: SolutionDetailDDH
  },

  {
    path: '/service',
    name: 'service',
    component: ServiceView
  },
  {
    path: '/service/ISIGN',
    name: 'serviceISIGN',
    component: ServiceDetailISIGN
  },
  {
    path: '/service/SOF',
    name: 'serviceSOF',
    component: ServiceDetailSOF
  },
  {
    path: '/service/CL',
    name: 'serviceCL',
    component: ServiceDetailCL
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    return { top }
  }
})

export default router
